import './App.css';
import Container from './components/Container';

import Menu from './components/Menu';

function App() {
  return (
    <div className="App">
      <Menu />
      <Container />
    </div>
  );
}

export default App;
